import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { API_BLOG_URL, trailing_key, CLIENT_SITE_URL, trailing_key_param } from "../../constants";

const BlogSitemap = () => {
  const [sitemapXML, setSitemapXML] = useState("");
  const { tag: pTag } = useParams();

  const fetchBlogs = async () => {
    try {
      let uri = `${API_BLOG_URL}/blogpost/`;
      if (pTag) uri += `tag/?tag=${pTag}${trailing_key_param}`;
      else uri += trailing_key;

      const response = await axios.get(uri);
      generateSitemap(response.data);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  // Genera el contenido del sitemap en formato XML
  const generateSitemap = (blogPosts) => {
    const urls = blogPosts.map((item) => {
      return `
        <url>
          <loc>${CLIENT_SITE_URL}/blog/${item.id}/${item.slug}</loc>
          <lastmod>${moment(item.updated || item.created).format("YYYY-MM-DD")}</lastmod>
          <priority>0.8</priority>
        </url>`;
    });

    const sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${urls.join("\n")}
      </urlset>`;

    setSitemapXML(sitemapContent);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <pre>
      {sitemapXML}
    </pre>
  );
};

export default BlogSitemap;
