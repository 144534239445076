import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {
  _DD_API_KEY,
  _ORS_KEY,
  _G_API_KEY,
  _G_MAPS_API_KEY,
  _G_oauth_CLIENT_ID,
  _BUGSNAG,
} from "./keys";

const PRODUCTION_ = false;
const PRODUCTION = true;

// logging de errores
Bugsnag.start({
  apiKey: _BUGSNAG,
  plugins: [new BugsnagPluginReact()],
});

Bugsnag.releaseStage = PRODUCTION ? "production" : "development";
Bugsnag.notifyReleaseStages = ["production", "staging"];

// api endpoint base
export const SITE_URL = PRODUCTION
  ? "https://api2.destinodocente.es"
  : "http://127.0.0.1:8080";

export const CLIENT_SITE_URL = PRODUCTION
  ? "https://destinodocente.es"
  : "http://localhost:3000";

export const MEDIA_URL = PRODUCTION
  ? "https://media.destinodocente.es"
  : "http://127.0.0.1:8080";

// contentype django para fav
export const CT_ADS = PRODUCTION ? 48 : 44;

export const API_CENTER_URL = SITE_URL + "/centers/api";
export const API_COUNTRY_URL = SITE_URL + "/countries/api";
export const API_AUTH_URL = SITE_URL + "/accounts/api";
export const API_PUSH_URL = SITE_URL + "/notifications/api";
export const API_PORTAL_URL = SITE_URL + "/portal/api";
export const API_ACCOUNTS_URL = SITE_URL + "/accounts/api";
export const API_TRAVELS_URL = SITE_URL + "/travels/api";
export const API_MESSAGES_URL = SITE_URL + "/messaging/api";
export const API_ADS_URL = SITE_URL + "/ads/api";
export const API_FAVS_URL = SITE_URL + "/favs/api";
export const API_BLOG_URL = SITE_URL + "/blog/api";


// keys
export const ORS_KEY = _ORS_KEY;
export const G_API_KEY = _G_API_KEY;
export const G_MAPS_API_KEY = _G_MAPS_API_KEY;
export const G_oauth_CLIENT_ID = _G_oauth_CLIENT_ID;

export const trailing_key = "?api_key=" + _DD_API_KEY;
export const trailing_key_param = "&api_key=" + _DD_API_KEY;
export const api_key = _DD_API_KEY;

export const IES_DENOM = 14;
export const CEIP_DENOM = 7;

export const BANNER_URI = PRODUCTION ? "https://ofertas.wiki/ofertas/day/strip-picture" : "http://127.0.0.1:8081/ofertas/day/strip-picture";
