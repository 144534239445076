import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Form,
  Button,
  notification,
  Skeleton,
  Select,
  Checkbox,
  Card,
} from "antd";

import { API_ACCOUNTS_URL, trailing_key } from "../../constants";
import { getUserById } from "../../services/auth-services";
import axiosAuth from "../utils/auth-interceptor";
import { optionRegions } from "../../constants/data";
import { convertCodesToOptions } from "../../utils/utils";

const UserComponent = ({ userId }) => {
  const [user, setUser] = useState(null);
  const [disabled, setDisabled] = useState(true);
  //const [error, setError] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (userId) => {
      try {
        const res = await getUserById(userId);
        setUser(res);
      } catch (error) {
        if (error.response?.status === 401) {
          const stateObj = { state: { from: window.location.pathname } };
          navigate("/salir", stateObj);
        } else {
          notification.warning({
            message: "Se ha producido un error",
          });
        }
      }
    };

    fetchData(userId);
  }, [userId]);

  const onChangeName = (value) => {
    user.first_name = value.target.value;
  };

  const onChangeLastName = (value) => {
    user.last_name = value.target.value;
  };

  const onChangeRegions = (value) => {
    user.regions = value;
  };

  const handleSubmit = () => {
    const uri = API_ACCOUNTS_URL + `/user/${userId}` + trailing_key;
    axiosAuth
      .put(uri, user)
      .then((response) => {
        setUser(response.data);
        notification.success({
          message: "Datos actualizados correctamente",
        });
        setDisabled(true);
      })
      .catch((error) => {
        notification.error({
          message: "Se ha producido un error al actualizar",
        });
      });
  };

  return (
    <>
      {!!user ? (
        <Card>
          <Form
            onSubmit={handleSubmit}
            fields={[
              {
                name: ["email"],
                value: !!user?.email ? user.email : undefined,
              },
              {
                name: ["first_name"],
                value: !!user?.first_name ? user.first_name : undefined,
              },
              {
                name: ["last_name"],
                value: !!user?.last_name ? user.last_name : undefined,
              },
              {
                name: ["regions"],
                value: !!user?.regions
                  ? convertCodesToOptions(user?.regions, optionRegions)
                  : [],
              },
              {
                name: ["disable_notifications"],
                checked: !!user?.disable_notifications
                  ? user.disable_notifications
                  : undefined,
              },
              {
                name: ["disable_email_notifications"],
                checked: !!user?.disable_email_notifications
                  ? user.disable_email_notifications
                  : undefined,
              },
              {
                name: ["disable_email_info"],
                checked: !!user?.disable_email_info
                  ? user.disable_email_info
                  : undefined,
              },
            ]}
          >
            <Form.Item label="Email" name="email">
              <Input
                disabled={true}
                rules={[
                  {
                    required: true,
                    message: "Por favor escribe tu email",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Nombre"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Por favor escribe tu nombre",
                },
              ]}
            >
              <Input onChange={onChangeName} disabled={disabled} />
            </Form.Item>
            <Form.Item label="Apellidos" name="last_name">
              <Input onChange={onChangeLastName} disabled={disabled} />
            </Form.Item>
            <Form.Item label="Comunidades favoritas" name="regions">
              <Select
                mode="multiple"
                disabled={disabled}
                onChange={onChangeRegions}
                options={optionRegions}
              />
            </Form.Item>
            <Form.Item name="disable_email_notifications">
              <Checkbox
                onChange={() =>
                  (user.disable_email_notifications =
                    !user.disable_email_notifications)
                }
                disabled={disabled}
              >
                Deshabilitar emails
              </Checkbox>
            </Form.Item>
            <Form.Item name="disable_email_info">
              <Checkbox
                onChange={() =>
                  (user.disable_email_info = !user.disable_email_info)
                }
                disabled={disabled}
              >
                Deshabilitar emails informativos y newsletter
              </Checkbox>
            </Form.Item>
            <Form.Item name="disable_notifications">
              <Checkbox
                onChange={() =>
                  (user.disable_notifications = !user.disable_notifications)
                }
                disabled={disabled}
              >
                Deshabilitar notificaciones push
              </Checkbox>
            </Form.Item>
            <div className="pull-right">
              {!!disabled ? (
                <Button type="primary" onClick={() => setDisabled(false)}>
                  Modificar
                </Button>
              ) : (
                <>
                  <Button onClick={() => setDisabled(true)}>Cancelar</Button>
                  {"     "}
                  <Button type="primary" onClick={() => handleSubmit()}>
                    Guardar
                  </Button>
                </>
              )}
            </div>
          </Form>
        </Card>
      ) : (
        <>
          <Skeleton className="p-3 mt-3" active />
        </>
      )}
    </>
  );
};

export default UserComponent;
