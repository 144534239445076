import React, { useState, useEffect } from "react";
import {
  List,
  Tooltip,
  Select,
  Form,
  Row,
  Col,
  Button,
  Card,
  Spin,
} from "antd";
//import { Switch, PullToRefresh, DotLoading } from "antd-mobile";
import { DotLoading } from "antd-mobile";
import { FaAngleRight, FaRegClock, FaRoad, FaDirections } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

import CenterDistance from "./center-distance-local";
import CenterPositions from "./center-positions-local";
import TravelButton from "./travels/travel-button";
import LoginButton from "./auth/login-button";
import { denomAbrev, optionsSort } from "../constants/data";
import { capitalize, durationColor } from "../utils/utils";
import { getLocalUserId } from "../services/auth-services";

import icoStraight from "../assets/straight-line.svg";
import background from "../assets/background.jpg";
import EmbeddedContent from "../utils/embed";
import { BANNER_URI } from "../constants";

const { Meta } = Card;

const emptyItem = {
  label: "Todos",
  value: "",
};

const MIN_DIST_TRAVEL = 4; // distancia minima para mostrar compartir coche

const CenterDistancesList = (props) => {
  const [listMarkers, setListMarkers] = useState(props.markers); // lista filtrada
  const [listMarkersOrig, setListMarkersOrig] = useState([]); // mantiene todos los items
  const [positionsValues, setPositionsValues] = useState([emptyItem]);
  const [defaultOrder, setDefaultorder] = useState({
    label: "Menor tiempo",
    value: "asc",
  });

  useEffect(() => {
    if (props.markers) setListMarkers(props.markers);
  }, [props.markers]);

  /**
   * ordena por tiempo la lista
   * @param {*} value
   */
  const handleSortChange = (value) => {
    const order = value;
    const newData = [...listMarkers].sort((a, b) => {
      if (a.roadDuration && b.roadDuration) {
        // me quedo solo con los valores numéricos
        const aDistance = Number(a.roadDuration.replace(/[^\d.-]/g, ""));
        const bDistance = Number(b.roadDuration.replace(/[^\d.-]/g, ""));

        if (order === "asc") {
          return aDistance - bDistance;
        } else {
          return bDistance - aDistance;
        }
      }
    });
    setListMarkers(newData);
  };

  /**
   * Filtra por puestos
   * @param {*} value
   */
  const handleFilterChange = (value) => {
    if (value && value !== "") {
      let newData = [...listMarkersOrig].filter(
        (marker) =>
          marker.positions &&
          marker.positions.some((position) => position === value)
      );
      setListMarkers(newData);
      props.setMarkers(newData); // par aactualizar el mapa
    } else {
      setListMarkers(listMarkersOrig);
      props.setMarkers(listMarkersOrig);
    }
  };

  /**
   * manejador para si tiene puestos
   * @param {*} checked
   *
  const handleSwitch = (checked) => {
    if (checked) {
      const newData = [...listMarkers].filter((marker) => marker.positions);
      setListMarkers(newData);
    } else {
      setListMarkers(props.markers);
    }
  };
  */
  /**
   * se llama desde el componente que obtiene las distancias para actualizar el erray markers y poder ordenar
   * @param {*} marker
   * @param {*} roadDistance
   * @param {*} roadDuration
   */
  const addDistanceToMarker = (marker, roadDistance, roadDuration) => {
    // Se crea un nuevo objeto que contiene el objeto item y los nuevos valores
    const newItem = { ...marker, roadDistance, roadDuration };
    // Se actualiza la matriz data con el nuevo objeto, si es que no tenia
    if (!marker.roadDistance && !marker.roadDuration) {
      for (let i = 0; i < listMarkers.length; i++) {
        if (marker.id === listMarkers[i].id) listMarkers[i] = newItem;
      }
      setListMarkersOrig(listMarkers); // copia con todos los items
      handleSortChange("asc"); // fuerzo ordenacion y toma los tiempos
    }
  };

  /**
   * se llama desde el control que obtiene los puestos para actualizar el erray markers y poder filtrar
   *
   * @param {*} marker
   * @param {*} positions
   */
  const addPositionsToMarker = (marker, markerPositions) => {
    // Se crea un nuevo objeto que contiene el objeto item y los nuevos valores
    const positions = Object.values(markerPositions).map((p) => {
      return p.position;
    });
    const newItem = { ...marker, positions };
    // Se actualiza la matriz data con el nuevo objeto, si es que no tenia
    if (!marker.positions) {
      // recorro la lista de marcadores y le añado puestos
      for (let i = 0; i < listMarkers.length; i++) {
        if (marker.id === listMarkers[i].id) listMarkers[i] = newItem;
      }

      // copia con todos
      setListMarkersOrig(listMarkers);
      // select de puestos
      let temp = positionsValues;
      for (let i = 0; i < positions.length; i++) {
        if (!temp.some((p) => p.value === positions[i])) {
          temp.push({
            label: capitalize(positions[i].split("-")[1].trim()),
            value: positions[i],
          });
        }
      }
      setPositionsValues(temp);
    }
  };

  /**
   * Render del item de lista
   * @param {*} item
   * @returns
   */
  const renderItem = (item) => {
    return (
      <List.Item
        key={item.id}
        className="ps-2 list-item"
        actions={[
          item.distance >= MIN_DIST_TRAVEL ? (
            <TravelButton
              munCodeOrig={props.munCode}
              munNameOrig={props.munName}
              centerDest={item}
              userId={getLocalUserId()}
              roadDuration={item.roadDuration}
              iconSize={28}
              className="p-0"
            />
          ) : (
            <div style={{ width: "20px" }}>&nbsp;</div>
          ),
          !!item.latitude && !!item.longitude && (
            <LoginButton
              className="px-0"
              title="Ruta en Google Maps"
              from={
                "/redirect?url=https://www.google.es/maps/dir/" +
                (props.aproxCoords ? props.aproxCoords : props.munCoords) +
                "/" +
                item?.latitude +
                "," +
                item?.longitude +
                "?travelmode=driving"
              }
              target="_blank"
            >
              <FaDirections size={22} style={{ color: "#26400C" }} />
            </LoginButton>
          ),
          <Button
            onClick={() => {
              props.onCenterClick(item);
            }}
            style={{ padding: 0, height: "100%" }}
            type="button"
          >
            <FaAngleRight size={26} />
          </Button>,
        ]}
      >
        <Row gutter={[4, 4]}>
          <Col span={24}>
            <h6>
              {item.code}
              {"   "}-{"   "}
              {denomAbrev[item.denom__name]} "{item.name}"
            </h6>
          </Col>
          <Col span={24}>
            <i className="fa fa-map-signs" aria-hidden="true"></i>{" "}
            <b>{item.city}</b>,{"  "}
            {item.province}
          </Col>
          <Col span={24}>
            <Row gutter={[0, 0]}>
              <CenterDistance
                munCode={props.munCode}
                aproxCoords={props.aproxCoords}
                idCenter={item.id}
                marker={item} // no render, solo calcula
                addDistanceToMarker={addDistanceToMarker}
              />
              <Col span={8}>
                <Tooltip title="Distancia en línea recta">
                  <div className="mt-2">
                    <img
                      src={icoStraight}
                      width="20px"
                      height="20px"
                      alt="distancia línea recta"
                    />{" "}
                    {Math.round(item.distance)} Km
                  </div>
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip title="Distancia por carretera">
                  <div className="road-distance px-0 mt-2">
                    <FaRoad />
                    {"  "}
                    {item.roadDistance ? item.roadDistance : <DotLoading />}
                  </div>
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip title="Tiempo por carretera">
                  <div className="road-duration px-0 mt-2">
                    <FaRegClock />{" "}
                    {item.roadDuration ? (
                      <>
                        <b>{item.roadDuration}</b>
                      </>
                    ) : (
                      <DotLoading />
                    )}
                  </div>
                </Tooltip>
                <style type="text/css">
                  {".marker-" +
                    item.id +
                    " {color: " +
                    durationColor(item.roadDuration) +
                    ";  text-shadow: 0 0 2px #26400C; }"}
                </style>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="mt-1">
            <div style={{ overflowY: "scroll" }}>
              <CenterPositions
                idCenter={item.id}
                code={item.code}
                marker={item}
                addPositionsToMarker={addPositionsToMarker}
              />
            </div>
          </Col>
        </Row>
      </List.Item>
    );
  };

  const loadingConfig = {
    indicator: (
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 48, color: "#26400C" }} spin />
        }
      />
    ),
    spinning: props.loading,
  };

  return (
    <>
      <Form
        layout="inline"
        className="py-2 ps-3"
        initialValues={[
          {
            name: ["position"],
            value: props.positionCode ? props.positionCode : null,
          },
        ]}
      >
        <Row
          style={{
            width: "100%",
          }}
        >
          {/*
          <Col span={12} className="d-none">
            <Form.Item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Sólo para Andalucía">
                  <Text strong style={{ marginRight: 5 }}>
                    Con puestos
                  </Text>
                  <Switch
                    onChange={handleSwitch}
                    disabled={props.disable}
                    style={{
                      "--checked-color": "#26400C",
                      "--height": "24px",
                      "--width": "48px",
                    }}
                  />
                </Tooltip>
              </div>
            </Form.Item>
          </Col>
          */}
          <Col span={12}>
            <Form.Item name={"order"}>
              <Select
                placeholder="Ordenar por tiempo"
                disabled={!props.markers || props.markers.length === 0}
                onChange={handleSortChange}
                options={optionsSort}
                defaultValue={defaultOrder}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"position"}>
              <Tooltip title="Sólo para Andalucía">
                <Select
                  placeholder="Filtrar por puesto"
                  disabled={
                    props.positionName
                      ? !positionsValues && positionsValues.length < 2
                      : !props.markers || props.markers.length === 0
                  }
                  onChange={handleFilterChange}
                  defaultValue={
                    props.positionName ? capitalize(props.positionName) : ""
                  }
                >
                  {positionsValues && positionsValues.length > 1 ? (
                    positionsValues.map((option) => (
                      <Select.Option
                        key={`pos_${option.value}`} // Usar option.value para asegurar la unicidad del key
                        value={option.value}
                      >
                        {option.label}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option
                      key={`pos_${props.positionCode}`}
                      value={props.positionCode}
                    >
                      {props.positionName}
                    </Select.Option>
                  )}
                </Select>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="list-container">
        <style type="text/css">
          {".ant-list-item-action {margin-inline-start: 5px !important}"}
        </style>
        {/*
        <PullToRefresh
          onRefresh={async () => {
            props.updateMarkers();
          }}
          pullingText="Actualizar"
          refreshingText="Actualizando ..."
          completeText="Actualizado"
          canReleaseText="Soltar"
        >*/}
        <List
          dataSource={listMarkers}
          renderItem={renderItem}
          style={{
            touchAction: "auto",
          }}
          locale={{
            emptyText: (
              <Card
                //visible={!props.loading}
                className="m-3"
                cover={
                  <img
                    alt="Fondo Destino Docente"
                    src={background}
                    style={{ height: "40vh" }}
                  />
                }
              >
                <Meta
                  title="Ahorra tiempo en tus destinos de secundaria y primaria"
                  description={
                    <>
                      No se han encontrado resultados. <br></br>
                      {props.positionName && (
                        <>
                          Has seleccionado el puesto{" "}
                          <strong>{props.positionName}</strong>. Escribe un
                          nombre de municipio o amplia el radio de búsqueda para
                          encontrar vacantes.
                        </>
                      )}
                    </>
                  }
                />
              </Card>
            ),
          }}
          loading={loadingConfig}
        />
        {/* </PullToRefresh> */}
        <EmbeddedContent uri={BANNER_URI} className="mb-0"/>
      </div>
    </>
  );
};

export default CenterDistancesList;
