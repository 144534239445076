import React, { useEffect, useState } from "react";
import axios from "axios";
import { SITE_URL, CLIENT_SITE_URL } from "../constants";
import background from "../assets/logo.png";

const EmbeddedContent = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchContent = async () => {
      try {
        //const uri = SITE_URL + "/portal/proxy/?url=" + (props.uri ? props.uri : "")
        const uri = CLIENT_SITE_URL + "/proxy.php?url=" + (props.uri ? props.uri : "")
        console.log(uri);
        const response = await axios.get(uri, {
          headers: {
            "Content-Type": "text/html",
          },
        });
        setContent(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <>
      {!!content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} className={props.className}/>
      ) : (
        <>
          {!!props.background && (
            <img
              alt="Destino Docente publicidad"
              src={background}
              style={{ maxWidth: "99vw" }}
              className="img img-responsive main-logo"
            />
          )}
        </>
      )}
    </>
  );
};

export default EmbeddedContent;
